import { HttpContextToken, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorModalService } from './error-modal.service';
import { ErrorComponent } from './error.component';

export const HAS_OWN_ERROR_HANDLING = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly errorModal: ErrorModalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!req.context.get(HAS_OWN_ERROR_HANDLING)) {
          if (error.status === 418 && error.error[0].severity === 'CRITICAL') {
            this.errorModal.nextError(error.error[0], ErrorComponent);
          }
        }
        return throwError(() => error);
      })
    );
  }
}
