import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { onSubscribe } from '@brz-scl/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AriaBusyService {
  private currentRequestCount = new BehaviorSubject(0);

  constructor(@Inject(DOCUMENT) document: Document) {
    this.currentRequestCount
      .pipe(
        map((count) => count > 0),
        distinctUntilChanged()
      )
      .subscribe((busy) => (document.body.ariaBusy = busy ? 'true' : 'false'));
  }

  register(through: Observable<any>) {
    const incrementCounter =
      (increment = 1) =>
      () =>
        this.currentRequestCount.next(this.currentRequestCount.value + increment);
    through?.pipe(onSubscribe(incrementCounter(1))).subscribe({
      complete: incrementCounter(-1),
      error: incrementCounter(-1),
    });
  }
}
