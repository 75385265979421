import { Component, Inject } from '@angular/core';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';
import { uspContactJson } from '@app/shared';

@Component({
  selector: 'egr-usp-contact',
  templateUrl: './usp-contact.component.html',
  styleUrls: ['./usp-contact.component.scss'],
})
export class UspContactComponent {
  readonly content = uspContactJson;
  readonly uspUrl = this.environment.uspDomain;

  constructor(@Inject(ENVIRONMENT) private readonly environment: EnvironmentProperties) {}
}
