import { Directive, Inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';

@Directive({ selector: '[egrHideProd]' })
export class HideProdDirective implements OnInit {
  constructor(
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentProperties,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    if (this.environment.name !== 'prod') {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
