import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[egrStopClick]',
})
export class StopClickDirective {
  @Input('egrStopClick') type: 'preventDefault' | 'stopPropagation' | 'none' | 'both' = 'both';

  @HostListener('click', ['$event'])
  onEvent(event: UIEvent) {
    switch (this.type) {
      case 'preventDefault':
        event.preventDefault();
        break;
      case 'stopPropagation':
        event.stopPropagation();
        break;
      case 'both':
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'none':
      default:
        break;
    }
  }
}
