import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SclAccordionModule, SclButtonModule, SclCollapseModule, SclDropdownModule, SclIconModule, SclInfoboxModule, SclInputModule } from '@brz-scl/core';

import { ImageInfoboxComponent, StepperComponent, UspContactComponent } from './components';
import { HideProdDirective, StopClickDirective } from './directives';
import { UspPublicPipe } from './pipes';

const sclModules = [SclAccordionModule, SclButtonModule, SclCollapseModule, SclDropdownModule, SclIconModule, SclInputModule, SclInfoboxModule];

@NgModule({
  declarations: [HideProdDirective, ImageInfoboxComponent, StepperComponent, StopClickDirective, UspContactComponent, UspPublicPipe],
  imports: [CommonModule, ...sclModules],
  exports: [CommonModule, HideProdDirective, StepperComponent, ImageInfoboxComponent, StopClickDirective, UspContactComponent, UspPublicPipe, ...sclModules],
})
export class SharedModule {}
