import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing-names';
import { map, take, tap } from 'rxjs/operators';

import { GruendungService } from './gruendung.service';

@Injectable({ providedIn: 'root' })
export class RechtsformGuard implements CanActivate {
  constructor(private gruendungService: GruendungService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.gruendungService.rechtsform$.pipe(
      tap((rechtsform) => (next.data = { ...next.data, rechtsform })),
      map((rechtsform) => {
        if (!rechtsform && state.url.includes(AppRoutingNames.GRUENDERKONTO)) {
          return this.router.parseUrl('/' + AppRoutingNames.RECHTSFORM);
        } else if (rechtsform && state.url.includes(AppRoutingNames.RECHTSFORM)) {
          return this.router.parseUrl('/' + AppRoutingNames.GRUENDERKONTO);
        } else {
          return true;
        }
      }),
      take(1)
    );
  }

  canLoad({ data, path }: Route) {
    return this.canActivate({ data } as ActivatedRouteSnapshot, { url: path } as RouterStateSnapshot);
  }
}
