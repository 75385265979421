<div *ngIf="upgradeStatus$ | async as upgradeStatus" [ngSwitch]="upgradeStatus" role="status">
  <ng-container *ngSwitchCase="UpgradeStatus.ALLOWED">
    <h1>{{ content.ALLOWED.heading }}</h1>

    <egr-stepper [activeIndex]="4"></egr-stepper>

    <p *ngFor="let p of content.ALLOWED.paragraphs" [innerHTML]="p | uspPublic"></p>

    <button scl-button class="scl-lg" (click)="upgrade()">{{ content.ALLOWED.action }}</button>
  </ng-container>

  <ng-container *ngSwitchCase="UpgradeStatus.PENDING">
    <p *ngFor="let p of content.PENDING.paragraphs">
      <strong class="center-horizontally">{{ p }}</strong>
    </p>
    <scl-spinner class="center-horizontally"></scl-spinner>
  </ng-container>

  <ng-container *ngSwitchCase="UpgradeStatus.SUCCESS">
    <ng-container *ngTemplateOutlet="successOrError"></ng-container>
    <a scl-button href="{{ logoutUrl }}" class="scl-lg">{{ content.SUCCESS.action }}</a>
  </ng-container>

  <ng-container *ngSwitchCase="UpgradeStatus.ERROR">
    <ng-container *ngTemplateOutlet="successOrError"></ng-container>
  </ng-container>

  <ng-template #successOrError>
    <h1 class="success-or-error">
      <i *ngIf="upgradeStatus === UpgradeStatus.SUCCESS; else errorIcon" scl-icon="success-fill" aria-hidden="true"></i>
      <ng-template #errorIcon>
        <i scl-icon="error-fill" aria-hidden="true"></i>
      </ng-template>
      {{ (upgradeStatus === UpgradeStatus.ERROR ? content.ERROR : content.SUCCESS).heading }}
    </h1>
    <p *ngFor="let p of (upgradeStatus === UpgradeStatus.ERROR ? content.ERROR : content.SUCCESS).paragraphs">
      <strong [innerHTML]="p | uspPublic"></strong>
    </p>
  </ng-template>
</div>
