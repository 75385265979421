import { Component, Input } from '@angular/core';

import stepperJson from './stepper.json';

@Component({
  selector: 'egr-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  readonly content = stepperJson;
  @Input() activeIndex: 1 | 2 | 3 | 4 = 1;
}
