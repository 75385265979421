import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { SessionTimeoutService } from './session-timeout.service';
import { SessionInterceptor } from './session.interceptor';

@NgModule({
  providers: [
    SessionTimeoutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
  ],
})
export class SessionModule {}
