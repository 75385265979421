import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { filter, map, shareReplay, take } from 'rxjs/operators';

// TODO replace with SCLBreadcrumb type if exported by SCL. PM3EGRE-205
export interface Breadcrumb {
  label: string;
  link: string;
}

const home: Breadcrumb = { label: 'Startseite', link: '/' };

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  readonly breadcrumbs$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.getBreadcrumbs(this.activatedRoute.root, '', [home])),
    shareReplay(1)
  );

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly router: Router, private readonly location: Location) {
    this.breadcrumbs$.pipe(take(1)).subscribe();
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string, breadcrumbs: Breadcrumb[]): Breadcrumb[] {
    if (!route.firstChild) {
      return breadcrumbs;
    }

    const snapshot = route.firstChild.snapshot;
    const segments = snapshot.url.map((segment) => segment.path);
    const hasSegments = segments.length;
    return this.getBreadcrumbs(
      route.firstChild,
      (url += hasSegments ? `/${segments.join('/')}` : ''),
      hasSegments ? [...breadcrumbs, { label: (this.location.getState() as Data).error?.title || snapshot.data.pageTitle, link: url }] : breadcrumbs
    );
  }
}
