import { Injectable } from '@angular/core';
import { SclModalService } from '@brz-scl/core';
import { ReplaySubject } from 'rxjs';

import type { ErrorComponent } from './error.component';
import { UspError } from './usp-error';

@Injectable({ providedIn: 'root' })
export class ErrorModalService {
  get error$() {
    return this.error.asObservable();
  }

  private readonly error = new ReplaySubject<UspError>(1);

  constructor(private modal: SclModalService) {}

  nextError(error: UspError, component: typeof ErrorComponent) {
    this.error.next(error);
    this.modal.open(
      { component },
      {
        title: error.title || 'Es ist ein Fehler aufgetreten',
        size: 'large',
        isDismissible: false,
        role: 'alertdialog',
      }
    );
  }
}
