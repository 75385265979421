import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { ErrorComponent } from './error.component';
import { ErrorInterceptor } from './error.interceptor';
import { GlobalErrorHandler } from './global-error-handler';

@NgModule({
  declarations: [ErrorComponent],
  imports: [SharedModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
})
export class ErrorModule {}
