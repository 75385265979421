<ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, isGruender: isGruender$ | async} as status">
  <ng-container *ngIf="status.isLoggedIn; else messageLoginRedirect">
    <ng-container *ngIf="status.isGruender; else messageDashboardRedirect">
      <scl-header>
        <scl-toolbar [skipLinks]="(skipLinks$ | async) || []">
          <scl-toolbar-logo
            imageUrl="/assets/images/header/BMDW_Unternehmensserviceportal_Logo_srgb.png"
            altText="Unternehmensservice Portal – Meine elektronische Unternehmensgründung"
            route="/"
            [srcImgWidth]="1362"
            [srcImgHeight]="338"
            [logoLines]="2"
          ></scl-toolbar-logo>
          <scl-toolbar-menu [menuItems]="toolbarLinks"></scl-toolbar-menu>
          <scl-dropdown *ngIf="user$ | async as user" [titleLines]="['Angemeldet als', user.firstName + ' ' + user.lastName]" [linkList]="userMenu">
            <i scl-icon="user-login">User Menü</i>
          </scl-dropdown>
          <scl-timeout-counter></scl-timeout-counter>
        </scl-toolbar>
        <scl-breadcrumbs [breadcrumbs]="(breadcrumbs$ | async) || []" [navigationAriaLabel]="'Positionsanzeige'" id="scl-breadcrumbs"
                         tabindex="-1"></scl-breadcrumbs>
      </scl-header>
      <egr-banner imageUrl="/assets/images/banner/rocket.png" [heading]="content.banner.heading" [subHeading]="content.banner.subHeading"></egr-banner>
      <scl-subheader *ngIf="user$ | async as user">
        {{ user.firstName + ' ' + user.lastName }}
        <strong class="scl-subheader-mobile-title">Mein Gründungskonto</strong>
        <a scl-button href="{{ changeCompanyUrl }}">Unternehmen wechseln</a>
      </scl-subheader>

      <scl-main-wrapper class="notification-wrapper">
        <scl-infobox type="warning" variant="1" heading="Hinweis">
          <p *ngFor="let p of content.eGruendungNotification.paragraphs" [innerHTML]="p"></p>
        </scl-infobox>
      </scl-main-wrapper>

      <scl-main-wrapper *ngIf="menu$ | async as menu">
        <scl-sidebar>
          <scl-side-menu *ngIf="menu.length" [menu]="menu" [ariaLabel]="content.sideMenu.ariaLabel" id="scl-side-menu" tabindex="-1"></scl-side-menu>
        </scl-sidebar>
        <ng-container *ngIf="{ user: user$ | async, gruendung: gruendung$ | async } as data">
          <egr-mpu-access-notice *ngIf="data.user && !data.user.mpuPermitted && data.gruendung && !data.gruendung.rechtsform"></egr-mpu-access-notice>
        </ng-container>
        <router-outlet></router-outlet>
      </scl-main-wrapper>

      <scl-footer id="scl-footer-nav" tabindex="-1">
        <scl-link-list [linkLists]="footerLinkLists"></scl-link-list>
        <scl-contact [contactLinks]="footerContactLinks"></scl-contact>
      </scl-footer>

      <egr-version-info *ngIf="showVersion"></egr-version-info>
    </ng-container>
  </ng-container>

  <ng-template #messageLoginRedirect>
    <!-- add extra if, bc isLoggedIn could be undefined here (if request isn't done yet) -->
    <p *ngIf="status.isLoggedIn === false" class="redirect redirect-to-login">Sie werden zur Anmeldeseite weitergeleitet ...</p>
  </ng-template>

  <ng-template #messageDashboardRedirect>
    <!-- add extra if, bc isGruender could be undefined here (if request isn't done yet) -->
    <p *ngIf="status.isGruender === false" class="redirect redirect-to-dashboard">Sie werden zu "Mein USP" weitergeleitet ...</p>
  </ng-template>
</ng-container>
