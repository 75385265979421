import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GruendungService, UserService } from '@app/core';
import { BreadcrumbService } from '@app/core/navigation/breadcrumb.service';
import { SideMenuService } from '@app/core/navigation/side-menu.service';
import { SkipLinkService } from '@app/core/navigation/skip-link.service';
import { SclLinkListModel, SclLinkOrActionModel } from '@brz-scl/core';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';
import { tap } from 'rxjs/operators';

import rootJson from './root.json';

const prefixExternalLinks = <T extends SclLinkOrActionModel>(list: T[], baseUrl: string) =>
  list.map((link) => (link.externalLink ? { ...link, externalLink: baseUrl + link.externalLink } : link));

const prefixExternalFooterLinks = (columns: SclLinkListModel[], baseUrl: string) =>
  columns.map((column) => (column.links.length > 0 ? { ...column, links: prefixExternalLinks(column.links, baseUrl) } : column));

@Component({
  selector: 'egr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  protected readonly changeCompanyUrl = this.environment.changeCompanyUrl;
  protected readonly content = rootJson;

  protected readonly toolbarLinks = prefixExternalLinks(this.content.toolbarLinks, this.environment.uspDomain);
  protected readonly footerContactLinks = prefixExternalLinks(this.content.footerContactLinks, this.environment.uspDomain);

  protected readonly footerLinkLists = prefixExternalFooterLinks(this.content.footerLinkLists, this.environment.uspDomain);

  protected readonly userMenu = this.content.userMenu.map(
    (link, i, a) => (i !== a.length - 1 ? link : { ...link, externalLink: this.environment.logoutUrl }) as SclLinkOrActionModel
  );

  private readonly homeTitle = this.titleService.getTitle();

  protected readonly breadcrumbs$ = this.breadcrumbService.breadcrumbs$.pipe(
    tap((breadcrumbs) => this.titleService.setTitle(breadcrumbs[breadcrumbs.length - 1].label + ' - ' + this.homeTitle))
  );
  protected readonly menu$ = this.sideMenuService.sideMenu$;

  protected readonly user$ = this.userService.user$;
  protected readonly isLoggedIn$ = this.userService.isLoggedIn$;
  protected readonly isGruender$ = this.userService.isGruender$;

  protected readonly gruendung$ = this.gruendungService.unfilteredGruendung$;
  protected readonly skipLinks$ = this.skipLinkService.skipLinks$;

  protected readonly showVersion= this.environment.showVersion;

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentProperties,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly sideMenuService: SideMenuService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly gruendungService: GruendungService,
    private readonly skipLinkService: SkipLinkService,
    private readonly renderer: Renderer2
  ) {
  }

  ngAfterViewInit() {
    const main = this.document.querySelector('main');
    if (main) {
      this.renderer.setAttribute(main, 'id', 'main-content');
      this.renderer.setAttribute(main, 'tabindex', '-1');
    }
  }
}
