import { Inject, Injectable } from '@angular/core';
import { SclModalService, SclTimeoutService } from '@brz-scl/core';
import { AuthControllerService } from '@brz-usp/usp-gruendung-mw-ngclient';
import { switchMap, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { ErrorComponent, errorMessages, ErrorModalService } from '../error';

const healthCheckInterval = 60 * 10 * 1000;
const timeToRedirect = 10 * 1000;

const content = `Sie werden in ${timeToRedirect / 1000} Sekunden automatisch zur Anmelde-Seite weitergeleitet.`;
const title = 'Ihre Sitzung ist abgelaufen!';

@Injectable({ providedIn: 'root' })
export class SessionTimeoutService {
  private redirected = false;

  constructor(
    @Inject(Window) private readonly window: Window,
    private readonly modal: SclModalService,
    private authClient: AuthControllerService,
    private timeoutService: SclTimeoutService,
    private errorModal: ErrorModalService
  ) {}

  start() {
    timer(0, healthCheckInterval)
      .pipe(
        switchMap(() => this.authClient.getSessionTtl()),
        takeWhile((ttl) => ttl > 0)
      )
      .subscribe({
        next: (ttl) => this.timeoutService.setCountdownTo(ttl),
        error: () => this.errorModal.nextError(errorMessages.offline, ErrorComponent),
        complete: () => this.causeRedirectToLogin(),
      });

    this.timeoutService.countdown$.subscribe({
      complete: () => {
        this.causeRedirectToLogin();
      },
    });
  }

  private causeRedirectToLogin() {
    if (this.redirected) {
      return;
    }
    this.redirected = true;
    this.modal.open(content, {
      isDismissible: false,
      title,
    });
    timer(timeToRedirect).subscribe(() => (this.window.location.href = '/'));
  }
}
