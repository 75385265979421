import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class NetscalerNoEmptyContentTypeInterceptor implements HttpInterceptor {
  private readonly contentTypeHeader = 'content-type';
  private readonly fallbackContentType = 'text/plain';

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.hasContentTypeHeader(req)) {
      const headers = req.headers.set(this.contentTypeHeader, this.fallbackContentType);
      return next.handle(req.clone({ headers }));
    }

    return next.handle(req);
  }

  private hasContentTypeHeader(req: HttpRequest<any>) {
    return (req.headers.getAll(this.contentTypeHeader)?.length ?? 0) > 0;
  }
}
