import { ErrorHandler, Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';

import errorMessages from './error-messages.json';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentProperties,
    private zone: NgZone,
    private router: Router
  ) {}

  handleError(error: Error) {
    if (this.environment.name !== 'prod') {
      console.log('error handled by GlobalErrorHandler: ', error);
    }
    this.zone.run(() => this.router.navigateByUrl('/error', {state: {error: errorMessages.generic}}));
  }
}
