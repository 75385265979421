import { Injectable } from '@angular/core';
import { isImprovementOrder } from '@app/modules/gruenderkonto/is-improvement-order';
import { onSubscribe } from '@brz-scl/core';
import { Gruendung, GruendungControllerService, Petition, SetPetitionStatusRequest, UpgradeStatus } from '@brz-usp/usp-gruendung-mw-ngclient';
import { BehaviorSubject, range, ReplaySubject, switchMap } from 'rxjs';
import { concatMap, endWith, filter, map, skip, take, tap } from 'rxjs/operators';
import { UserService } from '@app/core/session';

export const maxUpgradeAttempts = 3;

@Injectable({ providedIn: 'root' })
export class GruendungService {
  get gruendung$() {
    return this.unfilteredGruendung$.pipe(
      filter(
        (
          g
        ): g is Gruendung & {
          rechtsform: Gruendung.RechtsformEnum;
          areAllFormsDone: boolean;
          areRequiredFormsDone: boolean;
          areAllImprovementOrdersDone: boolean;
        } => !!g.rechtsform
      )
    );
  }

  /**
   * This separate getter was a necessary workaround for the MPU role warning infobox: it needed to know whether Rechtsform was definitely
   * received by the backend and is undefined.
   *
   * The filter on gruendung$ removes entries without rechtsform set, so that was useless. Meanwhile rechtsform$ returns null for unset
   * values, making the async pipe output null either way.
   *
   * Before refactoring either observable make sure you've considered *all* resulting changes to the application logic.
   */
  get unfilteredGruendung$() {
    return this.gruendung.pipe(
      filter((data) => Object.keys(data).length > 0),
      map((g) => ({
        ...g,
        areAllFormsDone: !!(g.petitions?.length && g.petitions.every((p) => p.status === Petition.StatusEnum.DONE)),
        areRequiredFormsDone: !!(g.petitions?.length && g.petitions.every((p) => isImprovementOrder(p.formType) || p.status === Petition.StatusEnum.DONE)),
        areAllImprovementOrdersDone: !!(
          g.petitions?.length && g.petitions.every((p) => !isImprovementOrder(p.formType) || p.status === Petition.StatusEnum.DONE)
        ),
      }))
    );
  }

  get rechtsform$() {
    return this.gruendung.pipe(
      filter((data) => Object.keys(data).length > 0),
      map((data) => data.rechtsform)
    );
  }

  get upgradeStatus$() {
    return this.upgradeStatus.asObservable();
  }

  private gruendung = new BehaviorSubject<Gruendung>({});
  private upgradeStatus = new ReplaySubject<UpgradeStatus>(1);

  constructor(private readonly gruendungApi: GruendungControllerService,
              private readonly userService: UserService) {
    // only fetch data when logged in AND a gruender (to prevent doing unnecessary requests)
    this.userService.isGruender$.pipe(
      filter(isGruender => isGruender),
      tap(() => {
        this.fetchData();
        this.fetchUpgradeStatus();
      })
    ).subscribe();
  }

  setFormsDoneNotified(wasNotified: boolean) {
    this.gruendungApi.setFormsDoneNotified(wasNotified).subscribe(() => this.gruendung.next({...this.gruendung.value, formsDoneNotified: wasNotified}));
  }

  setPetitionStatus(request: SetPetitionStatusRequest) {
    this.gruendungApi
      .setPetitionStatus(request)
      .pipe(
        tap(() => this.fetchData(request)),
        switchMap(() => this.gruendung.pipe(skip(1), take(1)))
      )
      .subscribe((g) => {
        if (g.formsDoneNotified && request.status !== Petition.StatusEnum.DONE && !isImprovementOrder(request.formType)) {
          this.setFormsDoneNotified(false);
        }
      });
  }

  setRechtsform(rechtsform: Gruendung.RechtsformEnum) {
    this.gruendungApi.setRechtsform(`"${rechtsform}"`).subscribe(() => this.fetchData());
  }

  addImprovementOrder() {
    this.gruendungApi.addImprovementOrder().subscribe(() => this.fetchData());
  }

  upgrade() {
    return range(0, maxUpgradeAttempts)
      .pipe(
        onSubscribe(() => this.upgradeStatus.next(UpgradeStatus.PENDING)),
        concatMap(() =>
          this.gruendungApi.upgrade().pipe(
            map((wrapper) => wrapper.status),
            filter((status) => status !== UpgradeStatus.ERROR && status !== UpgradeStatus.FORBIDDEN)
          )
        ),
        endWith(UpgradeStatus.ERROR),
        tap((status) => {
          if (status !== undefined) {
            this.upgradeStatus.next(status);
          }
        }),
        take(1)
      )
      .subscribe();
  }

  private fetchData(request?: SetPetitionStatusRequest) {
    const previousState = this.gruendung.value.petitions?.find(
      (p) => p.formType === request?.formType && (request?.petitionId === undefined || p.id === request?.petitionId)
    )?.status;

    this.gruendungApi.getUserInfo().subscribe((value) => {
      this.gruendung.next(this.mergeUiAndBackendStates(value, request, previousState));
    });
  }

  private mergeUiAndBackendStates(gruendung: Gruendung, request?: SetPetitionStatusRequest, previousState?: Petition.StatusEnum) {
    if (request && request.status === Petition.StatusEnum.IN_PROGRESS && previousState === Petition.StatusEnum.OPEN) {
      const petition = gruendung.petitions?.find((p) => p.formType === request.formType && (request?.petitionId === undefined || p.id === request?.petitionId));
      if (petition) {
        petition.status = request.status;
      }
    }
    return gruendung;
  }

  private fetchUpgradeStatus() {
    this.gruendungApi.getUpgradeStatus().subscribe({
      next: (wrapper) => {
        if (wrapper.status !== undefined) {
          this.upgradeStatus.next(wrapper.status);
        }
      },
    });
  }
}
