import { Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'egr-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() set imageUrl(url: string) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'background-image',
      `url('${url}'), url('${url}'), radial-gradient(circle at 50% 60%, white -100%, transparent 95%)`
    );
  }
  @Input() heading?: string;
  @Input() subHeading?: string;

  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}
}
