import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AriaBusyService } from './aria-busy.service';

@Injectable()
export class AriaBusyInterceptor implements HttpInterceptor {
  constructor(private readonly ariaBusyService: AriaBusyService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const ignoreRequest = req.url.endsWith('/auth/expiry');
    if (ignoreRequest) {
      return next.handle(req);
    }
    const loading = new Subject<void>();
    this.ariaBusyService.register(loading);
    return next.handle(req).pipe(
      finalize(() => {
        loading.next();
        loading.complete();
      })
    );
  }
}
