import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { SclSpinnerModule } from '@brz-scl/core';

import { UpgradeComponent } from './upgrade.component';

@NgModule({
  declarations: [UpgradeComponent],
  imports: [SharedModule, SclSpinnerModule],
})
export class UpgradeModule {}
