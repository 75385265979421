import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { GruendungService, SideMenuService } from '@app/core';
import { SclLinkOrActionModel } from '@brz-scl/core';
import { UpgradeStatus } from '@brz-usp/usp-gruendung-mw-ngclient';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export type SkipLinkModel = SclLinkOrActionModel & {
  accessKey: string;
  anchor: string;
};

@Injectable({ providedIn: 'root' })
export class SkipLinkService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly UpgradeStatus = UpgradeStatus;

  private readonly toContent = { anchor: 'main-content', label: 'Zum Inhalt', accessKey: '1' } as SkipLinkModel;
  private readonly toMainNav = { anchor: 'skip-link-default-target', label: 'Zur Hauptnavigation', accessKey: '2' } as SkipLinkModel;
  private readonly toSideNav = { anchor: 'scl-side-menu', label: 'Zur Unternavigation', accessKey: '3' } as SkipLinkModel;
  private readonly toBreadcrumbs = { anchor: 'scl-breadcrumbs', label: 'Zur Positionsanzeige', accessKey: '4' } as SkipLinkModel;
  private readonly toFooterNav = { anchor: 'scl-footer-nav', label: 'Zur Servicenavigation', accessKey: '5' } as SkipLinkModel;

  readonly skipLinks$ = combineLatest([
    this.breakpointObserver.observe(['(min-width: 1200px)']),
    this.sideMenuService.sideMenu$,
    this.gruendungService.upgradeStatus$,
  ]).pipe(
    map(([isLarge, sideMenu, upgradeStatus]) => {
      return [
        this.toContent,
        this.toMainNav,
        ...(isLarge.matches && sideMenu.length > 0 && upgradeStatus === this.UpgradeStatus.FORBIDDEN ? [this.toSideNav] : []),
        this.toBreadcrumbs,
        this.toFooterNav,
      ];
    })
  );

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly sideMenuService: SideMenuService,
    private readonly gruendungService: GruendungService
  ) {}
}
