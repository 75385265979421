import { EnvironmentProperties, EnvironmentPropertiesBase } from './';

export const factory = (base: EnvironmentPropertiesBase): EnvironmentProperties => {
  return {
    ...base,

    showVersion: base.showVersion ?? false,

    loginUrl: base.loginUrl ?? `${base.apiDomain}/asg/oidc/authorization/gruendung`,
    loginStatusUrl: base.loginStatusUrl ?? `${base.apiDomain}/asg/session`,
    logoutUrl: base.logoutUrl ?? `${base.apiDomain}/asg/session`,
    middlewareBaseUrl: base.middlewareBaseUrl ?? `${base.apiDomain}/mw`
  };
};
