import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

type TypeType = 'brand1' | 'brand1-reverse' | 'info';

@Component({
  selector: 'egr-image-infobox',
  templateUrl: './image-infobox.component.html',
  styleUrls: ['./image-infobox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageInfoboxComponent {
  @Input() imageSource!: string;
  @Input() imageAltText!: string;

  @Input() set type(value: TypeType | undefined) {
    this.hostClasses = this.getCssClassForType(value);
    this.hostClasses += value?.includes('reverse') ? ' reverse' : '';
  }

  private getCssClassForType(value: TypeType | undefined) {
    if (value === 'info') {
      return 'image-infobox-info';
    } else if (value) {
      return 'image-infobox-brand1';
    } else {
      return '';
    }
  }

  @HostBinding('class') private hostClasses = '';
}
