import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { fetchEnvironmentConfig } from '@app/core';

const developmentModeWhitelist = ['gruendung-dev.usp.gv.at', 'localhost', '127.0.0.1', 'gruendung3.usp.gv.at'];
if (!developmentModeWhitelist.includes(window.location.hostname)) {
  enableProdMode();
}

fetchEnvironmentConfig().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
