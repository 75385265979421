import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BASE_PATH as MW_BASE_PATH, Configuration as MwConfiguration, uspgrdgApiModule } from '@brz-usp/usp-gruendung-mw-ngclient';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, SessionTimeoutService, UserService } from './core';
import { SharedModule } from './shared';
import { filter, tap } from 'rxjs/operators';

const configuration = new MwConfiguration({withCredentials: true});
configuration.selectHeaderAccept = (_: string[]) => 'application/json';

const appInitializer = (userService: UserService, timeoutService: SessionTimeoutService) => () => {
  // subscribing to assertAuthorized$ checks login-state and does redirect
  userService.assertAuthorized$.pipe(
    // start the timeout-service if user is authorized
    filter(authorized => authorized),
    tap((_) => timeoutService.start())
  ).subscribe();
};

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserAnimationsModule, AppRoutingModule, uspgrdgApiModule, HttpClientModule, CoreModule, SharedModule],
  providers: [
    {
      provide: MW_BASE_PATH,
      useFactory: (environment: EnvironmentProperties) => environment.middlewareBaseUrl,
      deps: [ENVIRONMENT]
    },
    {provide: MwConfiguration, useValue: configuration},
    {provide: Window, useValue: window},
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: appInitializer,
      deps: [UserService, SessionTimeoutService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
