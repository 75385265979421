import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RechtsformGuard } from '@app/core/rechtsform.guard';

import { AppRoutingNames } from './app-routing-names';
import { ErrorComponent, errorMessages } from './core/error';
import { UpgradeComponent, UpgradeGuard } from './core/upgrade';
import rootJson from './root.json';

const routes: Routes = [
  { path: '', redirectTo: AppRoutingNames.RECHTSFORM, pathMatch: 'full' },
  {
    path: AppRoutingNames.RECHTSFORM,
    loadChildren: () => import('./modules/rechtsform/rechtsform.module').then((m) => m.RechtsformModule),
    canActivate: [UpgradeGuard, RechtsformGuard],
    canLoad: [UpgradeGuard, RechtsformGuard],
    data: {
      pageTitle: rootJson.pageTitles.rechtsform,
    },
  },
  {
    path: AppRoutingNames.GRUENDERKONTO,
    loadChildren: () => import('./modules/gruenderkonto/gruenderkonto.module').then((m) => m.GruenderkontoModule),
    canActivate: [UpgradeGuard, RechtsformGuard],
    canLoad: [UpgradeGuard, RechtsformGuard],
    data: {
      pageTitle: rootJson.pageTitles.gruenderkonto,
    },
  },
  {
    path: AppRoutingNames.UPGRADE,
    component: UpgradeComponent,
    canActivate: [UpgradeGuard],
    data: {
      pageTitle: rootJson.pageTitles.upgrade,
    },
  },
  {
    path: '**',
    component: ErrorComponent,
    data: {
      error: errorMessages.notFound,
      pageTitle: rootJson.pageTitles.notFound,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
