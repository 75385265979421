import { ChangeDetectionStrategy, Component } from '@angular/core';
import { version } from '@app/version';
import { SystemControllerService } from '@brz-usp/usp-gruendung-mw-ngclient';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'egr-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionInfoComponent {
  readonly uiVersion = version;

  readonly versions$ = this.systemController.getVersions().pipe(
    // show "unknown" if middleware isn't available
    catchError(() => of({ middleware: 'unknown', service: 'unknown' }))
  );

  constructor(private readonly systemController: SystemControllerService) {}
}
