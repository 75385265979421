import { EnvironmentProperties } from './';
import { InjectionToken } from '@angular/core';
import { factory } from '@app/core/environment/factory';

let environment: EnvironmentProperties = undefined as any;

export const fetchEnvironmentConfig = async () => {
  return fetch('config/environment.json')
    .then((response) => response.json())
    .then((env) => {
      environment = factory(env);
      if (environment.name.match(/^(dev|test)(-.*)?$/)) {
        console.log('active environment', environment);
      }
      return environment;
    });
};

export const ENVIRONMENT = new InjectionToken<EnvironmentProperties>('Angular environment', {
  providedIn: 'root',
  factory: () => environment,
});

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
