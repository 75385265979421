import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';

@Pipe({name: 'uspPublic'})
export class UspPublicPipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT) private readonly environment: EnvironmentProperties) {
  }

  transform(textWithLink: string) {
    return textWithLink
      .replace(/{{environment.mpuMelUrl}}/g, this.environment.mpuMelUrl)
      .replace(/{{environment.startupDomain}}/g, this.environment.startupDomain)
      .replace(/{{environment.uspDomain}}/g, this.environment.uspDomain);
  }
}
