import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing-names';
import { UpgradeStatus } from '@brz-usp/usp-gruendung-mw-ngclient';
import { map, take } from 'rxjs/operators';

import { GruendungService } from '../gruendung.service';

@Injectable({ providedIn: 'root' })
export class UpgradeGuard implements CanActivate, CanLoad {
  constructor(private readonly gruendung: GruendungService, private readonly router: Router) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.gruendung.upgradeStatus$.pipe(
      map((status) => {
        const isAbschluss = state.url.includes('abschluss');
        const isUpgradeForbidden = status === UpgradeStatus.FORBIDDEN;
        return (
          (isAbschluss && !isUpgradeForbidden) ||
          (!isAbschluss && isUpgradeForbidden) ||
          this.router.parseUrl('/' + (!isUpgradeForbidden ? AppRoutingNames.UPGRADE : ''))
        );
      }),
      take(1)
    );
  }

  canLoad({ path }: Route) {
    return this.canActivate({} as ActivatedRouteSnapshot, { url: path } as RouterStateSnapshot);
  }
}
