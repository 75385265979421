import { Location } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';
import { take, takeUntil, tap } from 'rxjs/operators';

import { SideMenuService } from '../navigation/side-menu.service';

import { ErrorModalService } from './error-modal.service';
import { UspError } from './usp-error';

@Component({
  selector: 'egr-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  @Input() set error(e: UspError | undefined) {
    this.sideMenuService.setSideMenu([]);
    this._error = e;
  }

  get error() {
    return this._error;
  }

  readonly errorForModal$ = this.errorModal.error$.pipe(tap((error) => this.environment.name !== 'prod' && !!error.trace && console.log(error.trace)));

  private _error?: UspError;

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentProperties,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly errorModal: ErrorModalService,
    private readonly sideMenuService: SideMenuService
  ) {}

  ngOnInit() {
    if (this.error) {
      return;
    }

    const uspError = (this.location.getState() as Data)?.error as UspError;
    if (!uspError?.message) {
      this.route.data.pipe(take(1), takeUntil(this.errorForModal$)).subscribe(({ error }) => (this.error = error));
    } else {
      this.error = uspError;
    }
  }
}
