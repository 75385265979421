import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { VersionInfoComponent } from '@app/core/version-info';
import { SharedModule } from '@app/shared/shared.module';
import {
  SclBreadcrumbsModule,
  SclContactModule,
  SclFooterModule,
  SclHeaderModule,
  SclInfoboxModule,
  SclLinkListModule,
  SclMainWrapperModule,
  SclModalModule,
  SclSideMenuModule,
  SclSpinnerModule,
  SclSubheaderModule,
  SclTimeoutCounterModule,
  SclToolbarModule,
} from '@brz-scl/core';

import { AriaBusyInterceptor } from './aria-busy';
import { BannerModule } from './banner';
import { ErrorModule } from './error';
import { MpuAccessNoticeComponent } from './mpu-access-notice';
import { SessionModule } from './session';
import { UpgradeModule } from './upgrade';
import { NetscalerNoEmptyContentTypeInterceptor, LoadingInterceptor } from './http';

const sclModules = [
  SclBreadcrumbsModule,
  SclContactModule,
  SclFooterModule,
  SclHeaderModule,
  SclInfoboxModule,
  SclLinkListModule,
  SclMainWrapperModule,
  SclModalModule,
  SclSideMenuModule,
  SclSpinnerModule,
  SclSubheaderModule,
  SclTimeoutCounterModule,
  SclToolbarModule,
];

@NgModule({
  declarations: [MpuAccessNoticeComponent, VersionInfoComponent],
  imports: [BannerModule, ErrorModule, SessionModule, UpgradeModule, SharedModule, ...sclModules],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AriaBusyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetscalerNoEmptyContentTypeInterceptor,
      multi: true,
    },
  ],
  exports: [BannerModule, ...sclModules, MpuAccessNoticeComponent, VersionInfoComponent],
})
export class CoreModule {}
