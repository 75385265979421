<dl>
  <dt>{{ content.contactList.telLabel }}</dt>
  <dd>{{ content.contactList.telDefinition }}</dd>

  <dt>{{ content.contactList.monThurLabel }}</dt>
  <dd>{{ content.contactList.monThurDefinition }}</dd>

  <dt>{{ content.contactList.friLabel }}</dt>
  <dd>
    {{ content.contactList.friDefinition }}<br />
    {{ content.contactList.holidayDefinition }}
  </dd>
</dl>

<a target="_blank" rel="noopener noreferrer" href="{{ uspUrl }}/kontaktformular.html">
  {{ content.contactForm }}<span class="scl-sr-only"> (neuer Tab)</span>
</a>
