<ng-container *ngIf="error; else modal">
  <h1 class="error-title">{{ error.title }}</h1>
  <p class="error-message">{{ error.message }}</p>
</ng-container>

<ng-template #modal>
  <ng-container *ngIf="errorForModal$ | async as errorForModal">
    <p class="error-modal-message">
      {{ errorForModal.message }}
      <ng-container *egrHideProd>
        <i *ngIf="errorForModal.trace" scl-icon="infobox-notice" title="Stack Trace im Browser-Log verfügbar."></i>
      </ng-container>
    </p>
    <a href scl-button class="scl-lg">Seite neu laden</a>
  </ng-container>
</ng-template>
