import { Inject, Injectable } from '@angular/core';
import { AuthControllerService, User } from '@brz-usp/usp-gruendung-mw-ngclient';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';
import { Observable, of, shareReplay } from 'rxjs';
import { HttpClient, HttpContext, HttpStatusCode } from '@angular/common/http';
import { HAS_OWN_ERROR_HANDLING } from '@app/core';

@Injectable({providedIn: 'root'})
export class UserService {

  readonly isLoggedIn$ = this.httpClient
    .get(this.environment.loginStatusUrl, {
      observe: 'response',
      responseType: 'text',
      context: new HttpContext().set(HAS_OWN_ERROR_HANDLING, true),
      withCredentials: true,
    }).pipe(
      catchError((err) => {
        // a non 401 error gets logged, but nevertheless treated as not logged-in
        if (err.status !== HttpStatusCode.Unauthorized) {
          console.error('cannot determine logged-in status, received unhandled response', err);
        }
        return of({status: HttpStatusCode.Unauthorized})
      }),
      map((ret) => ret.status === HttpStatusCode.Ok),
      shareReplay(1)
    );

  /**
   * user isn't logged in -> redirect to login-page
   */
  private readonly assertLoggedIn$ = this.isLoggedIn$.pipe(
    tap((isLoggedIn) => {
      if (!isLoggedIn) {
        console.log(`not logged in -> issue redirect to ${this.environment.loginUrl}`);
        this.window.location.href = this.environment.loginUrl;
      }
    }),
    shareReplay(1)
  );

  readonly user$: Observable<User> = this.isLoggedIn$.pipe(
    filter((isLoggedIn) => isLoggedIn),
    switchMap(() => this.authClient.getCurrentUser()),
    shareReplay(1)
  );

  readonly isGruender$ = this.isLoggedIn$.pipe(
    switchMap(() => this.user$),
    map(user => user.inGruendung === true)
  );

  /**
   * this observable will
   * - not emit     if user is not logged in
   * - emit false   if user is logged in + is a not gruender
   * - emit true    if user is logged in + is a gruender
   *
   * furthermore it will take care of redirecting to Mein USP (if not gruender) or the login-page (if not logged in)
   */
  readonly assertAuthorized$ = this.assertLoggedIn$.pipe(
    switchMap(() => this.isGruender$),
    tap((isGruender) => {
      if (!isGruender) {
        console.log(`logged in, but not a gruender -> issue redirect to ${this.environment.meinDomain}`);
        this.window.location.href = this.environment.meinDomain;
      }
    }),
  );

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentProperties,
    @Inject(Window) private readonly window: Window,
    private readonly authClient: AuthControllerService,
    private readonly httpClient: HttpClient
  ) {
  }
}

