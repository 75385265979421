import { Injectable } from '@angular/core';
import { SclSideMenuModel } from '@brz-scl/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SideMenuService {
  get sideMenu$() {
    return this.sideMenu.asObservable();
  }

  private sideMenu = new BehaviorSubject<SclSideMenuModel[]>([]);

  setSideMenu(sideMenu: SclSideMenuModel[]) {
    this.sideMenu.next(sideMenu);
  }
}
