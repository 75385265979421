import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UpgradeStatus } from '@brz-usp/usp-gruendung-mw-ngclient';
import { ENVIRONMENT } from '@env/environment';
import { EnvironmentProperties } from '@env/environment-properties';

import { GruendungService } from '../gruendung.service';

import upgradeJson from './upgrade.json';

@Component({
  selector: 'egr-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly UpgradeStatus = UpgradeStatus;

  readonly content = upgradeJson;
  readonly upgradeStatus$ = this.gruendung.upgradeStatus$;

  protected logoutUrl = this.environment.logoutUrl;

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentProperties,
    private readonly gruendung: GruendungService) {
  }

  upgrade() {
    this.gruendung.upgrade();
  }
}
